@import "../../data/styles.css";

.about-container {
  display: flex;
  flex-direction: column;
  padding-top: 80px;
}

.about-main {
  display: flex;
}

.about-right-side {
  display: flex;
  flex-direction: column;
  flex-basis: 66%;
}

.about-left-side {
  display: flex;
  flex-direction: column;
  flex-basis: 33%;
}

.about-initial {
  margin-bottom: -10px;
}

.about-title,
.about-subtitle {
  width: 80%;
  text-align: justify;
  text-justify: inter-word;
}

.about-image-wrapper {
  width: 100%;;
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
}

.about-image-wrapper img {
  display: block;
  width: 80%;
  border-radius: 50%;
  transform: rotate(3deg);
  box-shadow: 0px 0px 5px var(--tertiary-color);
}

.about-socials {
  padding-top: 10px;
}

.mobile-tag {
  display: none;
}

@media (max-width: 800px) {
  .about-main {
    flex-direction: column;
  }

  .about-left-side {
    flex-basis: 100%;
    order: 1;
  }

  .about-right-side {
    flex-basis: 100%;
    order: 2;
  }

  .about-image-wrapper {
    padding-top: 10px;
  }

  .about-image-wrapper img {
    display: block;
    width: 60%;
  }

  .about-title,
  .about-subtitle {
    width: 100%;
  }

  .mobile-tag {
    display:flex;
  }

  .desktop-tag {
    display: none;
  }
}
