.all-projects-container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: -35px;
  margin-right: -35px;
}

.all-projects-project {
  width: calc(100% / 3);
  box-sizing: border-box;
  overflow: hidden;
}

@media (max-width: 600px) {
  .all-projects-project {
    width: calc(100% / 2);
  }
}
