.skills-container {
  padding-top: 20px;
}

.skills-list {
  display: flex;
  list-style: none;
  align-items: left;
  flex-wrap: wrap;
  padding: 0px;
  font-size: 12px;
  justify-content: center;
}

.skill-tag {
  margin: 5px;
  flex-basis: 10%;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 8px;
  padding-left: 8px;
  border-radius: 20px;
  background-color: var(--tertiary-color);
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  cursor:pointer;
}

.about-banner-container {
  width: 100%;
}

.about-image {
  overflow: hidden;
  width: 100%;
  display: block;
}
