@import "../../data/styles.css";

.common-socials {
  display: flex;
  justify-content: left;
  align-items: center;
}

.common-social-icon {
  padding-right: 20px;
  font-size: 25px;
  transition: color 0.2s ease-in-out;
  color: var(--secondary-color);
}

.common-social-icon:hover {
  color: var(--tertiary-color);
}

@media (max-width: 600px) {
  .common-socials {
    padding-top: 20px;
  }
}
