@import "../../data/styles.css";

.nav-container {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 3vh;
  z-index: 999;
}

.nav-background {
  width: 380px;
  height: 40px;
  padding-left: 0%;
  padding-right: 0%;
  background: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
}

.nav-list {
  display: flex;
  justify-content: space-between;
  list-style: none;
  align-items: center;
  margin-left: -16px;
  margin-right: 20px;
  margin-top: 11px;
}

.nav-item {
  font-weight: bold;
  font-size: 90%;
}

.nav-item.active a {
  color: var(--link-color) !important;
}

.nav-item a {
  text-decoration: none;
  color: var(--primary-color);
}

.nav-item a:hover {
  color: var(--link-color);
  transition: color 0.3s ease-in-out;
}

@media (max-width: 600px) {
  .navbar {
    margin-left: 15%;
    margin-right: 15%;
    width: 90%;
  }

  .nav-background {
    height: 40px;
  }

  .nav-item {
    font-weight: bold;
  }
}
