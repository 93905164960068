@import "../../data/styles.css";

.footer {
  display: flex;
  flex-flow: row;
  margin-top: 25px;
  align-items: center;
}

.footer-social,
.footer-credits,
.footer-links {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.footer-nav-link-list {
  display: flex;
  list-style: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 0;
}

.footer-nav-link-item {
  font-weight: bold;
  font-size: 80%;
}

.footer-nav-link-list a {
  text-decoration: none;
  color: var(--secondary-color);
}

.footer-nav-link-list a:hover {
  color: var(--link-color);
}

.footer-credits-text {
  justify-content: flex-end;
  color: var(--secondary-color);
  font-size: 14px;
}

@media (max-width: 1024px) {
  .footer {
    flex-direction: column;
  }

  .footer-nav-link-list {
    padding-left: 20%;
    padding-right: 20%;
  }

  .footer-nav-link-item{
    margin-left: -30px;
  }

}
