.socials{
  display: flex;
}

.socials-column {
  flex-basis: 50%;
}

.social {
  display: flex;
  padding-bottom: 10px;
  justify-content: center;
}

.social a {
  text-decoration: none;
}

.social-icon {
  color: var(--secondary-color);
  display: flex;
  width: 35px;
  margin-top: 2px;
}

.social-text {
  color: var(--secondary-color);
  font-weight: 600;
  font-size: 14px;
  display: flex;
}

.social-icon:hover,
.social-text:hover {
  color: var(--tertiary-color);
}
